<template>
    <v-main>
        <v-container>
            <v-row class="mb-4">
                <v-col class="text-h4">
                    Divisions &amp; Approvers
                </v-col>
                <v-col
                    cols="5"
                    class="d-flex justify-end align-center"
                >
                    <NewDivisionDialog
                        @refreshGroups="refresh"
                    />
                    <EditDivisionDialog 
                        @refreshGroups="refresh"
                    />
                </v-col>
            </v-row>
            <template v-if="loading">
                <div v-for="i in 7" :key="i">
                    <v-skeleton-loader type="heading" class="mb-2" />
                    <v-skeleton-loader type="divider" class="mb-2" />
                    <v-col cols="4">
                        <v-skeleton-loader type="text,text,text,text" />
                    </v-col>
                </div>
            </template>
            <template v-else>
                <div v-for="(div, i) in divisionApprovers" :key="i">
                    <div class="text-h6" v-text="div.name" />
                    <v-divider class="mb-2" />
                    <v-row class="mb-1 text--secondary overline" no-gutters>
                        <v-col cols="12" sm="1" />
                        <v-col cols="12" md="4" class="ml-3">Approvers</v-col>
                        <v-col cols="12" md="4">Requesters</v-col>
                    </v-row>
                    <v-row
                        class="body-2 mb-6"
                        no-gutters
                    >
                        <v-col cols="1" class="body-1 font-weight-bold text-right">
                            HR:
                        </v-col>
                        <v-col class="ml-3" md="4" cols="12">
                            <ManageGroup
                                group-filter="hr-approvers-"
                                new-group-prefix="hr-approvers-"
                                :division="div"
                                :group="div.hr.approver_group"
                                :members="div.hr.approver_group_members"
                            />
                        </v-col>
                        <v-col cols="4">
                            <ManageGroup
                                group-filter="hr-requesters-"
                                new-group-prefix="hr-requesters-"
                                :division="div"
                                :group="div.hr.requester_group"
                                :members="div.hr.requester_group_members"
                            />
                        </v-col>
                    </v-row>
                    <v-row
                        class="body-2 mb-6"
                        no-gutters
                    >
                        <v-col cols="1" class="body-1 font-weight-bold text-right">
                            PUR:
                        </v-col>
                        <v-col class="ml-3" cols="4">
                            <ManageGroup
                                group-filter="pur-approvers-"
                                new-group-prefix="pur-approvers-"
                                :group="div.pur.approver_group"
                                :members="div.pur.approver_group_members"
                                :division="div"
                            />
                        </v-col>
                        <v-col cols="4">
                            <ManageGroup
                                group-filter="pur-requesters-"
                                new-group-prefix="pur-requesters-"
                                :group="div.pur.requester_group"
                                :members="div.pur.requester_group_members"
                                :division="div"
                            />
                        </v-col>
                    </v-row>
                </div>
            </template>
        </v-container>
    </v-main>
</template>
<script>
import { ref, computed, onMounted } from '@vue/composition-api';

import ManageGroup from '@/components/admin/ManageGroup';
import NewDivisionDialog from '@/components/admin/NewDivisionDialog';
import EditDivisionDialog from '@/components/admin/EditDivisionDialog';

export default {
    components: {
        ManageGroup,
        NewDivisionDialog,
        EditDivisionDialog
    },
    setup(_, { root }) {

        onMounted(() => {
            refresh();
        });

        const loading = ref(true);
        const divisionApprovers = computed(() => root.$store.getters['populationAdmin/getDivisionApprovers']);

        const refresh = async () => {
            loading.value = true;
            await root.$store.dispatch('populationAdmin/loadDivisionApprovers');
            loading.value = false;
        };

        const chgDivName = (div) => {
            console.log(div)
        }

        return { loading, divisionApprovers, refresh, chgDivName };
    }
}
</script>