<template>
	<v-dialog v-model="config.dialog" persistent max-width="800px" eager>
		<template v-slot:activator="{ on, attrs }">
			<v-btn v-bind="attrs" v-on="on" color="primary" outlined class="ml-2">Edit Division Name</v-btn>
		</template>
		<v-form v-model="config.formValid" lazy-validation ref="form">
			<v-card>
				<v-card-title>
					<span class="headline">Edit Division Name</span>
				</v-card-title>
				<v-card-text>
					<v-select
						v-model="parentDivision"
						:items="parentDivisionList"
						item-text="long_name"
						label="Parent Division"
						return-object
						@change="resetChildDivision"
					></v-select>
					<v-select
						v-if="parentDivision && computedChildDivisionList.length !== 0"
						v-model="childDivision"
						:items="computedChildDivisionList"
						item-text="short_name"
						label="Sub-Division"
						return-object
					></v-select>
					<v-text-field
						v-model="divisionName"
						:rules="divisionNameRules"
						required
						:label="divisionNameLabel"
					/>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="resetDialog">Close</v-btn>
					<v-btn
						color="blue darken-1"
						text
						:disabled="!config.formValid || config.loading"
						:loading="config.loading"
						@click="submit"
					>Change</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>
<script>

import axios from 'axios';
import { reactive, onMounted, ref, computed } from '@vue/composition-api';

export default {
	name: "EditDivision",
	emits:['refreshGroups'],
	components: {},
	setup(props, { root, refs, emit }) {
		const store = root.$store;

		const config = reactive({
			dialog: false,
			loading: false,
			formValid: false,
		});
		const parentDivision = ref(null);
		const childDivision = ref(null);
		const divisionName = ref(null);
		const getDivisionList = computed(() => store.getters['populationAdmin/getEditDivisionList']);

// const divisionList = reactive({
// parent: [],
// child: []
// })

		const parentDivisionList = computed(() => {
			// return getDivisionList.value.filter(div => div.parent === null);
			if (!getDivisionList.value) return
			return getDivisionList.value.parent;
		});

		const childDivisionList = computed(() => {
			// return getDivisionList.value.filter(div => div.parent !== null);
			if (!getDivisionList.value) return
			return getDivisionList.value.child;
		});

		const computedChildDivisionList = computed(() => {
			if (!parentDivision.value) return;

			let childList = childDivisionList.value.filter(div => div.parent == parentDivision.value.id);

			if (childList.length > 0) return [{id: null, short_name: '--- Edit Parent Name ---'}].concat(childList)
			
			return []
		});

		const divisionNameLabel = computed(() => {
			return parentDivision.value && (childDivision.value && childDivision.value.id) ? 'New Sub-Division Name' : 'New Parent Division Name';
		});


		const divisionNameRules = computed(() => {
			return parentDivision.value && (childDivision.value && !childDivision.value.id) ? 
			root.$rules.required.concat(v => /^\d+\s-\s/.test(v) || 
				'Invalid Parent Division Name (must begin with ## - )') : root.$rules.required;
		});


		const resetDialog = () => {
			config.dialog = false;
			parentDivision.value = parentDivisionList.value[0];
			childDivision.value = null;
			divisionName.value = null;
			refs.form.resetValidation();
		};

		const submit = async () => {
			config.loading = true;
			if (refs.form.validate()) {
				let payload = {
					parentDivision: parentDivision.value,
					childDivision: childDivision.value,
					newDivisionName: divisionName.value
				};
				const response = await axios.post(`/population/admin/edit_divison/`, payload);
				if (!response.data.error) {
					store.commit('setSuccessSnack', `Successfully changed division '${response.data.division.long_name}'`);
					emit('refreshGroups')
					await store.dispatch('populationAdmin/loadEditDivisions');
					resetDialog();
				} else {
					store.commit('setErrorSnack', response.data.message);
				}
			}
			config.loading = false;
		};

		const resetChildDivision = () => {
			childDivision.value = null
		}

		onMounted(async () => {
			await store.dispatch('populationAdmin/loadEditDivisions');
			parentDivision.value = parentDivisionList.value[0];
		});

		return {
			config,
			getDivisionList,
			parentDivisionList,
			childDivisionList,
			computedChildDivisionList,
			divisionNameLabel,
			divisionNameRules,
			submit,
			resetDialog,
			parentDivision,
			childDivision,
			divisionName,
			resetChildDivision
		};
	}
};
</script>
