<template>
    <v-dialog v-model="dialog" persistent max-width="800px" eager>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                outlined
            >
                Create New Division
            </v-btn>
        </template>
        <v-form
            v-model="formValid"
            lazy-validation
            ref="form"
        >
            <v-card>
                <v-card-title>
                    <span class="headline">Create New Division</span>
                </v-card-title>
                <v-card-text>
                    <v-select
                        v-model="parentDivision"
                        :items="divisionList"
                        item-text="long_name"
                        label="Parent Division"
                        return-object
                    ></v-select>
                    <v-text-field
                        v-model="divisionName"
                        :rules="divisionNameRules"
                        required
                        :label="divisionNameLabel"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="resetDialog">Close</v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        :disabled="!formValid || loading"
                        :loading="loading"
                        @click="submit"
                    >Create</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import axios from 'axios';

export default {
    name: "NewDivisionDialog",
    data() {
        return {
            dialog: false,
            loading: false,
            formValid: true,
            parentDivision: null,
            divisionName: null
        };
    },
    components: {
    },
    computed: {
        ...mapGetters({
            getDivisionList: 'population/getDivisionList'
        }),
        divisionList() {
            return [{id: null, long_name: '--- New Parent Division ---'}, ...this.getDivisionList];
        },
        divisionNameLabel() {
            return this.parentDivision && this.parentDivision.id ? 'New Sub-Division Name' : 'New Division Name';
        },
        divisionNameRules() {
            return this.parentDivision && this.parentDivision.id ? this.$rules.required : this.$rules.required.concat(v => /^\d+\s-\s/.test(v) || 'Invalid Parent Division Name (must begin with ## - )');
        }
    },
    methods: {
        resetDialog() {
            this.dialog = false;
            this.parentDivision = this.divisionList[0];
            this.divisionName = null;
            this.$refs.form.resetValidation();
        },

        async submit() {
            this.loading = true;
            if (this.$refs.form.validate()) {
                let payload = {
                    parentDivision: this.parentDivision,
                    newDivisionName: this.divisionName
                };
                const response = await axios.post(`/population/admin/new_division/`, payload);
                if (!response.data.error) {
                    this.$store.commit('setSuccessSnack', `Successfully added division '${response.data.division.long_name}'`);
                    this.$emit('refreshGroups')
                    this.$store.dispatch('population/loadDivisions', {parentOnly: true});
                    this.resetDialog();
                } else {
                    this.$store.commit('setErrorSnack', response.data.message);
                }
            }
            this.loading = false;
        }
    },
    mounted() {
        this.$store.dispatch('population/loadDivisions', {parentOnly: true});
        this.parentDivision = this.divisionList[0];
    }
};
</script>